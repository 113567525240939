import React from "react";
// import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import WorkHeroSection from "../components/sections/work-hero-section";
import { DataProvider } from "../contentful/data-provider";
import WorksSection from "../components/sections/works-section";
import Seo from "../components/seo";

function WorkSingle({ data }) {
	const work = data.contentfulWork;
	const dataProvider = new DataProvider(data);
	const relatedWorks = data.allContentfulWork.edges;
	return (
		<Layout dataProvider={dataProvider}>
			<Seo
				dataProvider={dataProvider} title={`GlassView | Work - ${work.title}`}
				description={work.metaDescription}
			/>
			<main className={"pt-24 lg:pt-24 pb-12"}>
				<section className='pb-10 md:pb-15'>
					<div className='container'>
						<h1 className={"h2 text-black"}>{work.title}</h1>
						<div className={"blue-title mt-4"}>{work.category.title}</div>
					</div>
				</section>

				<WorkHeroSection work={work} />

				<section className='work-content-row-section'>
					<div className='container'>
						<div className={"flex flex-wrap"}>
							<div className={"basis-full md:basis-3/12"}>
								<h2 className={"h5 text-black mb-5"}>{dataProvider.getLabel("objective")}</h2>
							</div>
							<div className={"basis-full md:basis-9/12 text-base"}>{renderRichText(work.objective)}</div>
						</div>
					</div>
				</section>

				<section className='work-content-row-section'>
					<div className='container'>
						<div className={"flex flex-wrap"}>
							<div className={"basis-full md:basis-3/12"}>
								<h2 className={"h5 text-black mb-5"}>{dataProvider.getLabel("strategy")}</h2>
							</div>
							<div className={"basis-full md:basis-9/12 text-base"}>{renderRichText(work.strategy)}</div>
						</div>
					</div>
				</section>

				<section className='mb-10 work-content-row-section no-border'>
					<div className='container'>
						<div className={"flex flex-wrap"}>
							<div className={"basis-full md:basis-3/12"}>
								<h2 className={"h5 text-black mb-5"}>{dataProvider.getLabel("results")}</h2>
							</div>
							<div className={"basis-full md:basis-9/12 text-base"}>{renderRichText(work.results)}</div>
						</div>
					</div>
				</section>

				<WorksSection
					content={{
						title: dataProvider.getLabel("you-may-also-like"),
						elements: relatedWorks.map((relatedWork) => {
							return {
								id: relatedWork.node.id,
								title: relatedWork.node.title,
								previewImage: relatedWork.node.previewImage,
								logo: relatedWork.node.logo,
								category: relatedWork.node.category,
								slug: relatedWork.node.slug,
								node_locale: relatedWork.node.node_locale,
							};
						}),
					}}
				/>
			</main>
		</Layout>
	);
}

export default WorkSingle;

export const query = graphql`
	query WorkBySlug($slug: String!, $categorySlug: String!, $locale: String) {
		contentfulWork(slug: { eq: $slug }, node_locale: { eq: $locale }) {
			title
			id
			metaDescription
			category {
				title
			}
			metrics {
				name
				value
			}
			previewImage {
				title
				url
			}
			previewVideo {
				url
				title
			}
			slug
			objective {
				raw
			}
			strategy {
				raw
			}
			results {
				raw
			}
			video {
				url
				title
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
		allContentfulWork(limit: 6, filter: { category: { slug: { eq: $categorySlug } }, slug: { ne: $slug }, node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					title
					node_locale
					previewImage {
						title
						url
					}
					logo {
						title
						url
					}
					category {
						title
					}
					slug
				}
			}
		}
	}
`;

//comentedhead
