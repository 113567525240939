import * as React from "react";
import BtnPlay from "../elements/btn-play";
import BtnMute from "../elements/btn-mute";

const WorkHeroSection = (props) => {
	function showVideo(video) {}
	const [muted, setMuted] = React.useState(true);

	return (
		<section className='work-hero-section'>
			<div className='container'>
				<div className={"flex flex-wrap items-center"}>
					<div className={"basis-full md:pr-14 lg:pr-28 md:basis-4/5"}>
						<div className={"video"}>
							<div className={"video-container"}>
								<video
									id={"previewVideo"}
									src={props.work.previewVideo.url}
									loop={true}
									autoPlay={true}
									muted={muted}
									playsInline={true}
								/>
								<button
									onClick={() => setMuted(!muted)}
									className='absolute top-0 right-0 grid transition-all duration-300 ease-in-out delay-75 mute-button place-content-center play-button group hover:scale-95'
								>
									{muted && (
										<svg
											className='mx-auto transition-all duration-300 ease-in-out group-hover:transform group-hover:scale-90'
											xmlns='http://www.w3.org/2000/svg'
											width='34'
											height='34'
											viewBox='0 0 34 34'
											fill='none'
										>
											<path
												d='M8.5 9.91658L16.4407 4.95363C17.9176 4.03057 19.8333 5.09236 19.8333 6.83397V27.1659C19.8333 28.9075 17.9176 29.9693 16.4407 29.0462L8.5 24.0832V9.91658ZM0 21.1267C0 22.7596 1.32368 24.0832 2.95652 24.0832H5.66667V9.91658H2.95652C1.32368 9.91658 0 11.2403 0 12.8731V21.1267ZM29.7124 6.30771C29.1746 5.71979 28.2605 5.73942 27.6971 6.30285L27.6505 6.34945C27.066 6.93396 27.0811 7.87973 27.6151 8.51074C29.6184 10.8781 30.8125 13.815 30.8125 16.9999C30.8125 20.1845 29.6197 23.1211 27.6161 25.4875C27.0818 26.1186 27.0663 27.0648 27.6511 27.6495L27.6985 27.697C28.2619 28.2604 29.176 28.2801 29.7138 27.6921C32.3846 24.7721 34 21.0594 34 16.9999C34 12.9403 32.3845 9.22858 29.7124 6.30771ZM28.0146 16.9999C28.0146 14.5448 27.1182 12.2867 25.6195 10.4356C25.1115 9.80801 24.1791 9.82086 23.6081 10.3918L23.5342 10.4657C22.9644 11.0355 22.9693 11.9499 23.4268 12.6133C24.3096 13.8932 24.8271 15.3907 24.8271 16.9999C24.8271 18.6106 24.3118 20.1095 23.4298 21.3907C22.9731 22.0542 22.9684 22.9683 23.5379 23.5379L23.612 23.6119C24.1831 24.183 25.1158 24.1957 25.6235 23.5676C27.1204 21.7159 28.0146 19.4559 28.0146 16.9999Z'
												fill='white'
											/>
										</svg>
									)}
									{!muted && (
										<svg
											className='mx-auto transition-all duration-300 ease-in-out group-hover:transform group-hover:scale-90'
											xmlns='http://www.w3.org/2000/svg'
											width='34'
											height='34'
											viewBox='0 0 34 34'
											fill='none'
										>
											<path
												d='M8.5 9.91658L16.4407 4.95363C17.9176 4.03057 19.8333 5.09236 19.8333 6.83397V27.1659C19.8333 28.9075 17.9176 29.9693 16.4407 29.0462L8.5 24.0832V9.91658ZM0 21.1267C0 22.7596 1.32368 24.0832 2.95652 24.0832H5.66667V9.91658H2.95652C1.32368 9.91658 0 11.2403 0 12.8731V21.1267Z'
												fill='white'
											/>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M32.3136 11.8432C31.923 11.4527 31.2899 11.4527 30.8993 11.8432L27.3644 15.3782L23.8294 11.8432C23.4389 11.4527 22.8057 11.4527 22.4152 11.8432L21.7081 12.5503C21.3176 12.9408 21.3176 13.574 21.7081 13.9645L25.243 17.4995L21.707 21.0356C21.3164 21.4261 21.3164 22.0593 21.707 22.4498L22.4141 23.1569C22.8046 23.5474 23.4378 23.5474 23.8283 23.1569L27.3644 19.6208L30.9005 23.1569C31.291 23.5474 31.9242 23.5474 32.3147 23.1569L33.0218 22.4498C33.4123 22.0593 33.4123 21.4261 33.0218 21.0356L29.4857 17.4995L33.0207 13.9645C33.4112 13.574 33.4112 12.9408 33.0207 12.5503L32.3136 11.8432Z'
												fill='white'
											/>
										</svg>
									)}
								</button>
								<BtnMute muted={muted} setMuted={setMuted} />
								<div className={"button-container"}>
									<BtnPlay onClick={() => showVideo(props.work.video)} />
								</div>
							</div>
						</div>
					</div>
					<div className={"basis-full px-[30px] md:px-0 md:basis-1/5"}>
						<div className={"metrics"}>
							<div className={"flex flex-wrap"}>
								{props.work.metrics.map((work, index) => {
									return (
										<div className={"basis-1/2 sm:basis-auto md:basis-full metric-col pt-4"} key={index}>
											<span>{work.value}</span>
											{work.name}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WorkHeroSection;
